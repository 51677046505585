.call-to-action {
    padding: 8px;
    border-radius: 5px;
    transition: 0.3s;
    color: #000;
    width: 100%;
    display: block;
    text-align: center;
}

.call-to-action a {
    color: #000;
}

.call-to-action:hover {
    filter: grayscale(0.3)
}

.number-slide {
    display: block;
    width: 70px;
    height: 70px;
    border-radius: 70px;
    font-size: 30px;
    text-align: center;
    line-height: 70px;
}

.call-to-action[data-type='nutrizione'] {
    background-color: #FFC63B;
}
.call-to-action[data-type='psicologia'] {
    background-color: #73B9C0;
}
.call-to-action[data-type='fisioterapia'] {
    background-color: #D289B8;
}

.bio-modal-body {
    position: relative;
}
.bio-btn {
    display: inline-block;
    height: 50px;
    width: 50px;
    background-color: transparent;
    margin-left: 8px;
    margin-right: 8px;
}