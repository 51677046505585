@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

html, body {
    background-color: #fff;
}

#app-container {
    min-height: calc(100vh - 180px);
    background-color: rgb(227 244 244);
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    color: #000;
    margin-top: 120px;
}

body.scrolled #app-container {
    margin-top: 60px;
}

#app-container h1 {
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 600;
    word-break: break-word;
}

#app-container p, #app-container li {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 14px;
    font-weight: 300;
}

#app-container p b {
    font-weight: 600;
}

#app-container p.lead {
    font-size: 18px;
}

#app-container a {
    text-decoration: none;
}

#app-container hr {
    width: 60px;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 768px) {
    .only-desktop {
        display: none !important;
    }
}

@media only screen and (min-width: 768px) {
    .only-mobile {
        display: none !important;
    }
}