header {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    border-bottom: 1px solid rgba(100,100,100,0.2);
    z-index: 99;
    background-color: #fff;
    height: 120px;
    transition: all linear 0.2s;
}

#header-logo {
    transition: all linear 0.2s;
}

header[data-scroll=true] {
    height: 60px;
}

header[data-scroll=true] #header-logo {
    height: 60px;
}